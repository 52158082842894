import * as React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import SocialMedia from "../components/SocialMedia"
//import ContactForm from "../components/ContactForm"
//import Newsletter from "../components/Newsletter"

const ContactPage = ({ data }) => (
  <Layout>
    <SEO title="Contact" />
    <div className="container mx-auto text-center">
      <h1 className="text-3xl uppercase font-bold mt-10 mb-6">Contact Me</h1>
      <p className="text-2xl mb-6 w-2/3 mx-auto">
        Feel free to send me an email and let's talk.
      </p>
      <p className="text-xl">
        <span className="font-bold">Email: </span>
        <span className="text-blue-600">info@designtypist.com</span>
      </p>
      <div className="mx-auto w-1/2 my-10">
        <Img fluid={data.file.childImageSharp.fluid} />
      </div>
    </div>
  </Layout>
)

export default ContactPage

export const query = graphql`
  query {
    file(relativePath: { eq: "images/contact-me.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`